<template>
	<router-view :key="$store.state.app.language" />
	<router-link :to="{name:'whatsNew'}" class="kaveh-version">
		<small dir="ltr">{{ ($store.state.app.language).toUpperCase() }} . {{version}}</small>
	</router-link>
</template>
<script>
	import { toast } from '@/plugins'
	export default {
		data()
		{
			return {
				version: '3.2.0'
			}
		},

		created()
		{
			setTimeout(() =>
			{
				var version =
					localStorage.getItem("kaveh.app.version");

				if (version && this.version != version)
				{
					toast.notification({
						destination: "/whats-new",
						msg: `Update To ${this.version}`
					})
				}

				localStorage.setItem("kaveh.app.version", this.version)
			},2000)
		}
	}
</script>
<style>
	.kaveh-version {
		position: fixed;
		bottom: 2px;
		left: 2px;
		z-index: 10000;
	}

	[dir="ltr"] .kaveh-version {
		left: unset;
		right: 2px;
	}
</style>

