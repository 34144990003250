const en = {
	service: {
		hub: "Infrastructure",
		shop: "Online Store",
		cms: "Content Management",
		guard: "Guards",
		cpay: "Credit Payment",
		mail: "Letters",
		flow: "Processes",
		post: "Posts",
		nem: "Network Equipment",
		messenger: "Messenger",
		asset: "Files",
		cms: "Content management system",
		store: "Store",
		finance: "Financial",
		ai: "Artificial intelligence",
		notification: "Notifications"

	},
	global: {
		applicationName: "Kaveh Software",
		signIn: "Sign In",
		logOut: "Log Out",
		save: "Save Data",
		ApplyFilters: "Apply Filters",
		excelKey: "Excel Key",
		operations: "Operations",
		mathOperators: "Math Operators",
		RelOperators: "Relational Operators",
		logicOperators: "Logical Operators",
		copyright: "Designed and produced by Kaveh Software Group",
		filters: "Filters",
		tools: "Tools",
		yes: "Yes",
		no: "No",
		row: "Row",
		name: "Name"
	},

	languages: {
		fa: "persian",
		en: "english",
		de: "germany",
	},

	system: {
		pleaseWait: "Please wait...",
		pleaseWaite: "Please wait...",
		downloading: "The system is downloading data, please wait.",
		uploading: "The system is uploading data, please wait.",
		progressing: "The system is performing an operation, please wait.",
		notfound: "No data found for display!",
		dataError: "An error occurred while retrieving data! Please contact the system support.",
		wellcome: "Welcome to the Kaveh Organizational Integrated System. </br> This system is developed using cutting-edge web technologies and can be installed on various operating systems."
	},
	layout: {
		default: "Default Template",
		minimal: "Minimal Template"
	},
	router: {
		back: "Back"
	},
	status: {
		errorLen: "Error Count",
		successLen: "Success Count",
		errorShort: "Unsuccessful Operation",
		successShort: "Successful Operation",
		error: "Operation encountered an error.",
		ok: "Operation completed successfully.",
		notFound: "Value not found -- {message}",
		unknown: "Internal server error! Please contact system support. {message}",
		unavailable: "Service unavailable",
		internal: "Internal error {message}",
		unauthorized: "You do not have access to this section or your session has ended. Please log in again.",
		unauthenticated: "Your session has ended. Please log in again.",
		invalidArgument: "The entered data is not valid. {message}",
		alreadyExists: "The entered data already exists. {message}",
		permissionDenied: "Invalid access permission. {message}"
	},
	message: {
		attachmentImageAllert: "This page is for uploading public images. Do not upload any confidential files here.",
		deleteRelationError: "Due to the existence of relationships with other sections of the system, deletion is not possible",
		invalidPrimaryKey: "Invalid primary key",
		networkError: "Error connecting to server!",
		serverError: "Internal server error! Please contact system support.",
		noResponse: "Server not available! No response received for the request.",
		badRequest: "Invalid request!",
		unavailableService: "Service unavailable",
		emptyResult: "No data found for display",
		permissionDenied: "You do not have access to this section. Please coordinate with the system administrator to complete your access levels.",
		unauthorized: "You do not have access to this section or your session has ended. Please log in again.",
		loginError: "Incorrect username or password",
		deleteConfirm: "Are you sure you want to delete?",
		confirm: "Are you sure?",
		confirmSyncDistributeDatabase: "You are synchronizing shared data between distributed databases! Synchronization always occurs automatically. If there is an error in the distributed system, continue this operation. The operation may take some time. Are you sure you want to continue the operation?"
	},
	action: {
		create: "Add",
		save: "Save Data",
		responce: "Response",
		enter: "Enter",
		exit: "Exit",
		sendResponce: "Send Response",
		createNewNode: "Add New Branch",
		update: "Edit",
		fileAttachment: "File Attachment",
		imageAttachment: "Image Attachment",
		changeStatus: "Change status",
		download: "Download",
		uploadSelectedFiles: "Upload Selected Files",
		filter: "Filter",
		import: "Import Data",
		createImport: "Import Data",
		export: "Export Data",
		exportExcel: "Export as Excel",
		importFromExcel: "Import from Excel",
		ApplyFilters: "Apply Filters",
		history: "View History",
		importData: "Import Data",
		delete: "Delete",
		deleteData: "Delete Data",
		back: "Back",
		close: "Close",
		search: "Search",
		searchPoint: "Search...",
		more: "More",
		applyFilter: "Apply Filter",
		toggleFilter: "Toggle Filter",
		showList: "Show List",
		submitChange: "Apply Changes",
		pleaseSelect: "Please Select",
		downloadReport: "Download Report",
		neverMind: "Never mind",
		prev: "Previous",
		next: "Next"
	},
	page: {
		kaveh: "Kaveh Software",
		home: "Home",
		profile: "Profile",
		changePass: "Change Password",
		notFound: "Page not found",
		cpanel: "Kaveh Software",
		baseInformation: "System Basic Information",
		auth: "System Login",
		logout: 'Logging out',
		dashboard: "Dashboard",
		baseInfo: "Basic Information Management",
		baseSys: "System Information",
		service: "Services",
		cycles: "Cycles",
		accessDenied: "Access Denied",
		print: "Print"
	},
	error: {
		checkForm: "Please first check and resolve form errors.",
		required: "Field {label} is required",
		invalid: "Field {label} is invalid",
		digit: "Field {label} must be a non-zero digit",
		number: "Field {label} must be a number",
		stringLength: "Field {label} exceeds the maximum allowed character count",
		noUnicodeString: "Only English characters and numbers are allowed",
		byteOutOfRange: "The maximum allowed value for this field is 255",
		int16OutOfRange: "The maximum allowed value for this field is 32,767",
		"uint16OutOfRange": "The maximum allowed value for this field is 65,535",
		int32OutOfRange: "The maximum allowed value for this field is 2,147,483,647",
		uint32OutOfRange: "The maximum allowed value for this field is 4,294,967,295",
		int64OutOfRange: "The maximum allowed value for this field is 9,223,372,036,854,775,807",
		uint64OutOfRange: "The maximum allowed value for this field is 18,446,744,073,709,551,615",
		password: "The minimum password length must be 8 characters"
	},
	title: {
		attachmentsList: "Attachments List"
	},



	data: {
		uniquekey: "Unique Key",
		track: {
			self: "Registration Information",
			creatorId: "Creator ID",
			creatorIp: "Creator IP",
			creatorName: "Creator Name",
			createDate: "Creation Date (Gregorian)",
			createUtcDate: "Creation Date (UTC)",
			localCreateDate: "Creation Date (Persian)",
			createTime: "Creation Time",
			createUtcTime: "Creation Time (UTC)",
			modifierId: "Modifier ID",
			modifierIp: "Modifier IP",
			modifierName: "Modifier Name",
			modifyDate: "Modification Date (Gregorian)",
			modifyUtcDate: "Modification Date (UTC)",
			localModifyDate: "Modification Date (Persian)",
			modifyTime: "Modification Time",
			modifyUtcTime: "Modification Time (UTC)",
			summary: "By {creatorName} on {localCreateDate}"
		},
		userAgent: {
			self: "User Information",
			userName: "Username",
			password: "Password"
		},
		history: {
			self: "History",
			entityValue: "Changes",
			historyName: "Registration Type",
			fullName: "User Full Name",
			id: "User ID",
			ip: "User IP"
		},
		historyTime: {
			self: "Time Information",
			createDate: "Date (Gregorian)",
			createPersianDate: "Date (Persian)",
			createTime: "Time"
		},
		baseEntityForm: "Form Information"
	},
	com: {
		excel: {
			chooseFile: "Choose File",
			backToSelectFile: "Back",
			review: "Review",
			reviewLength: "Number of Reviews Performed",
			NumberOfReviewsPerformed: "Number of Reviews Performed",
			reviewMessage: "If no errors are found after review, the system will proceed to data entry.",
			fileIsEmpty: "Selected file is empty!",
			downloadSampleFile: "Download Sample File",
			fileProcessing: "System is processing the file, please wait.",
			fileHasError: "File contains invalid data.",
			warning: "Please pay attention to the following points when entering data via Excel file:",
			sampleFileName: "Sample File {sheetName} - {id}"
		},
		table: {
			filterResult: "Filtered on {totalfilters} rows.",
			pageInfo: "Page {currentPage} of {totalPages}",
			action: {
				exportCurentPageAsExcel: "Download Current Page as Excel",
				createReport: "Create Report"
			},
			headerSearch: "Search in {label}",
			searchPlaceholder: "Enter your desired value and press Enter",
			report: {
				progressing: "System is creating and downloading the report, please wait...",
				done: "Operation completed successfully. File will be downloaded automatically."
			}
		},
		task: {
			repeat: "Repeat",
			skip: "Skip",
			replace: "Replace",
			cancel: "Cancel Operation",
			doTasks: "Perform Tasks",
			cancelTasks: "Cancel Tasks",
			errorContent: "Error Content",
			taskRowNumberError: "Record number {index} encountered an error.",
			tasksFailedLenght: "Number of Failed Records",
			tasksSucceedLenght: "Number of Successful Records",
			applyToAllCases: "Apply this selection to all future cases with similar errors",
			progressing: "System is performing the operation, please wait.",
			tasksCompletedWithError: "Operation completed with errors.",
			tasksCompletedSuccess: "Operation completed successfully.",
			excelRowIndex: "Excel File Row Number",
			errorDetail: "Excel File Row Number",
			warning: "The following data is selected for {label}:"
		},
		uploader: {
			chooseMessage: "Drag and drop your file here or click the button to choose a file.",
			chooseButton: "Choose File",
			addFile: "Add New File",
			count: "Selected {label} files",
			allowExtensions: "Accepted formats",
			errors: {
				invalidType: "File format for {label} is invalid",
				duplicate: "File {label} has already been selected",
				longName: "Maximum allowed file name length is 50 characters"
			}
		},
		common: {
			cd: {
				warning: "Dear user, you are currently deleting data! If you are sure about this operation, click the Delete Data button.",
				ok: "Delete Data",
				cancel: "Cancel and Return"
			}
		},
		form: {
			checkbox: {
				yes: "Yes",
				no: "No"
			},
			title: {
				require: "'{label}' field is required",
				unique: "'{label}' field must be unique",
				requireUnique: "'{label}' field is required and must be unique"
			},
			generator: {
				entityName: "Default Form",
				head: {
					controls: "Fields",
					preview: "Preview",
					config: "Settings",
					patterns: "Patterns"
				},
				controls: {
					text: "Text Field",
					number: "Number Field",
					textarea: "Text Area",
					password: "Password Field",
					select: "Multi-Select Field",
					checkdrop: "Yes-No Field",
					color: "Color Field",
					datePicker: "Date Field",
					timePicker: "Time Field",
					signaturePad: "Digital Signature",
					textEditor: "Text Editor",
					hr: "Separator",
					h3: "Title",
					lable: "Helper Text",
					removeConfirm: "Are you sure you want to delete?",
					defaultValue: "Default Value"
				},
				config: {
					label: "Title",
					isUnicode: "Allow Persian typing",
					select: {
						head: "Selections",
						text: "Title",
						value: "Value",
						textNumber: "Select number {label}",
						serverAddress: "Server Address"
					},
					patterns: {
						required: "Field is required",
						mobile: "Mobile Number pattern",
						nationalCode: "National ID pattern",
						email: "Email pattern"
					}
				}
			},
			select: {
				noOption: "No value found for display."
			}
		},
		textEditor: {
			table: {
				rowCount: "Number of Rows",
				colCount: "Number of Columns",
				create: "Create Table"
			}
		},
		camera: {
			open: "Connect to Camera",
			close: "Disconnect Camera",
			take: "Capture Image"
		},
		popup: {
			closeConfirm: "Are you sure you want to close?"
		}
	}
}

export default en
