


const isUnicode = (value) =>
{
	for (var i = 0, n = (value || "").length; i < n; i++)
	{
		if (value.charCodeAt(i) > 255) { return true; }
	}

	return false;
}

const atob = (value) =>
{
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	let str = String(value).replace(/=+$/, '');
	let output = '';

	if (str.length % 4 === 1)
	{
		throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
	}

	for (let bc = 0, bs = 0, buffer, i = 0; (buffer = str.charAt(i++)); ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0)
	{
		buffer = chars.indexOf(buffer);
	}

	return output;
}


const btoa = (value) =>
{
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	let str = String(value);
	let output = '';

	for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || (map = '=', i % 1); output += map.charAt(63 & block >> 8 - i % 1 * 8))
	{
		charCode = str.charCodeAt(i += 3 / 4);
		if (charCode > 0xFF)
		{
			throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
		}
		block = block << 8 | charCode;
	}

	return output;
}

const base64ToUint8Array = (value) =>
{
	const padding = '='.repeat((4 - value.length % 4) % 4);
	const base64 = (value + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i)
	{
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
}



export default {
	isUnicode: isUnicode,
	atob: atob,
	btoa: btoa,
	base64ToUint8Array: base64ToUint8Array
}
